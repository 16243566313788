<template>
  <tr>
    <td>
      <strong>{{ client.id }}</strong>
    </td>
    <td class="nowrap-column">
      <router-link :to="{ name: 'client.details', params: { clientId: client.id }}"
                   class="btn btn-success pull-right margin-l-5"
                   title="Детали"
                   type="button">
        <i class="fa fa-info-circle"></i>
      </router-link>
    </td>
    <td class="nowrap-column">
      {{ client.name }}
    </td>
    <td>
      <a :href="'tel:+' + client.phone">
        <strong>{{ client.phone }}</strong>
      </a>
      <clipboard-block :data="client.phone" custom_class="btn-default"/>
      <template v-if="client.phone_two">
        <br/>
        <a :href="'tel:+' + client.phone_two" class="font-small">
          <strong>{{ client.phone_two }}</strong>
        </a>
        <clipboard-block :data="client.phone_two" custom_class="btn-default"/>
      </template>
      <template v-if="client.phone_three">
        <br/>
        <a :href="'tel:+' + client.phone_three" class="font-small">
          <strong>{{ client.phone_three }}</strong>
        </a>
        <clipboard-block :data="client.phone_three" custom_class="btn-default"/>
      </template>
      <small v-if="client.email">
        <br/>
        <a :href="'mailto:' + client.email">
          {{ client.email }}
        </a>
      </small>
      <small v-if="client.city">
        <br/>
        Город: <strong>{{ client.city.name }}</strong>
      </small>
    </td>
    <td class="nowrap-column">
      {{ client.history.length > 0 ? client.history[0].action_type : '—' }}
    </td>
    <td class="nowrap-column">
      {{ client.source_type }}
      <strong v-if="client.partner">
        <br/>
        {{ client.partner.name }}
      </strong>
    </td>
    <td class="nowrap-column">
      {{ client.updated_date }}
      <small v-if="client.creator">
        <br/>
        Добавил <strong>{{ client.creator.name }}</strong>
      </small>
      <small v-if="client.editor">
        <br/>
        Обновил <strong>{{ client.editor.name }}</strong>
      </small>
    </td>
    <td class="nowrap-column">
      <router-link :to="{
                     name: 'client.edit',
                     params: {
                       clientId: client.id
                     }
                   }"
                   class="btn btn-primary"
                   style="margin: 3px 2px 2px 3px"
                   title="Редактировать">
        <i class="fa fa-edit"></i>
      </router-link>

      <button class="btn btn-danger" style="margin: 3px 2px 2px 3px"
              title="Удалить" type="button"
              v-on:click="removeClient(client.id)">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';
import ClipboardBlock from './../../components/blocks/clipboard-block.vue';

Vue.use(VuejsDialog);

export default {
  name: 'client-item',

  components: {
    ClipboardBlock,
  },

  props: {
    client: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    removeClient(clientId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/clients/' + clientId).then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
